import { defineMessages } from 'react-intl';

export default defineMessages({
  argentina: {
    id: 'argentina',
    defaultMessage: 'Argentina',
  },
  australia: {
    id: 'australia',
    defaultMessage: 'Australia',
  },
  austria: {
    id: 'austria',
    defaultMessage: 'Austria',
  },
  belgiumFr: {
    id: 'belgiumFr',
    defaultMessage: 'Belgium FR',
  },
  belgiumNl: {
    id: 'belgiumNl',
    defaultMessage: 'Belgium NL',
  },
  brazil: {
    id: 'brazil',
    defaultMessage: 'Brazil',
  },
  canadaEn: {
    id: 'canadaEn',
    defaultMessage: 'Canada EN',
  },
  canadaFr: {
    id: 'canadaFr',
    defaultMessage: 'Canada FR',
  },
  chile: {
    id: 'chile',
    defaultMessage: 'Chile',
  },
  china: {
    id: 'china',
    defaultMessage: 'China',
  },
  colombia: {
    id: 'colombia',
    defaultMessage: 'Colombia',
  },
  czechia: {
    id: 'czechia',
    defaultMessage: 'Czechia',
  },
  denmark: {
    id: 'denmark',
    defaultMessage: 'Denmark',
  },
  finland: {
    id: 'finland',
    defaultMessage: 'Finland',
  },
  france: {
    id: 'france',
    defaultMessage: 'France',
  },
  germany: {
    id: 'germany',
    defaultMessage: 'Germany',
  },
  hongKong: {
    id: 'hongKong',
    defaultMessage: 'Hong Kong',
  },
  hungary: {
    id: 'hungary',
    defaultMessage: 'Hungary',
  },
  india: {
    id: 'india',
    defaultMessage: 'India',
  },
  ireland: {
    id: 'ireland',
    defaultMessage: 'Ireland',
  },
  italy: {
    id: 'italy',
    defaultMessage: 'Italy',
  },
  japan: {
    id: 'japan',
    defaultMessage: 'Japan',
  },
  luxembourg: {
    id: 'luxembourg',
    defaultMessage: 'Luxembourg',
  },
  mexico: {
    id: 'mexico',
    defaultMessage: 'Mexico',
  },
  newZealand: {
    id: 'newZealand',
    defaultMessage: 'New Zealand',
  },
  norway: {
    id: 'norway',
    defaultMessage: 'Norway',
  },
  peru: {
    id: 'peru',
    defaultMessage: 'Peru',
  },
  poland: {
    id: 'poland',
    defaultMessage: 'Poland',
  },
  portugal: {
    id: 'portugal',
    defaultMessage: 'Portugal',
  },
  russia: {
    id: 'russia',
    defaultMessage: 'Russia',
  },
  spain: {
    id: 'spain',
    defaultMessage: 'Spain',
  },
  sweden: {
    id: 'sweden',
    defaultMessage: 'Sweden',
  },
  switzerland: {
    id: 'switzerland',
    defaultMessage: 'Switzerland',
  },
  theNetherlands: {
    id: 'theNetherlands',
    defaultMessage: 'The Netherlands',
  },
  turkey: {
    id: 'turkey',
    defaultMessage: 'Turkey',
  },
  unitedKingdom: {
    id: 'unitedKingdom',
    defaultMessage: 'United Kingdom',
  },
  unitedStates: {
    id: 'unitedStates',
    defaultMessage: 'United States',
  },
  moreNews: {
    id: 'moreNews',
    defaultMessage: 'More News',
  },
  mostRead: {
    id: 'mostRead',
    defaultMessage: 'Most Read',
  },
  relatedNews: {
    id: 'relatedNews',
    defaultMessage: 'Related News',
  },
  relatedJobs: {
    id: 'relatedJobs',
    defaultMessage: 'Related Jobs',
  },
  topStories: {
    id: 'topStories',
    defaultMessage: 'Top Stories',
  },
  financialNews: {
    id: 'financialNews',
    defaultMessage: 'Financial News',
  },
  latestJobs: {
    id: 'latestJobs',
    defaultMessage: 'Latest Jobs',
  },
  jobOfTheWeek: {
    id: 'jobOfTheWeek',
    defaultMessage: 'Job Of The Week',
  },
  managementJobs: {
    id: 'managementJobs',
    defaultMessage: 'Management Jobs',
  },
  categories: {
    id: 'categories',
    defaultMessage: 'Categories',
  },
  applyButtonText: {
    id: 'applyButtonText',
    defaultMessage: 'Apply Here',
  },
  featured: {
    id: 'featured',
    defaultMessage: 'Featured',
  },
  partnerContent: {
    id: 'partnerContent',
    defaultMessage: 'Partner Content',
  },
  partnerContentMetaDescription: {
    id: 'partnerContentMetaDescription',
    defaultMessage: 'Partner Content',
  },
  partnerContentHref: {
    id: 'partnerContentHref',
    defaultMessage: '/partner-content',
  },
  pressReleases: {
    id: 'pressReleases',
    defaultMessage: 'Press Releases',
  },
  pressReleasesMetaDescription: {
    id: 'pressReleasesMetaDescription',
    defaultMessage: 'Latest Press Releases',
  },
  pressReleasesHref: {
    id: 'pressReleasesHref',
    defaultMessage: '/press-releases',
  },
  category: {
    id: 'category',
    defaultMessage: 'Category',
  },
  'category.news.fashion': {
    id: 'category.news.fashion',
    defaultMessage: 'Fashion',
  },
  'category.news.fashion.href': {
    id: 'category.news.fashion.href',
    defaultMessage: '/news/fashion',
  },
  'category.news.fashion.param': {
    id: 'category.news.fashion.param',
    defaultMessage: 'news/fashion',
  },
  'category.news.retail': {
    id: 'category.news.retail',
    defaultMessage: 'Retail',
  },
  'category.news.retail.href': {
    id: 'category.news.retail.href',
    defaultMessage: '/news/retail',
  },
  'category.news.retail.param': {
    id: 'category.news.retail.param',
    defaultMessage: 'news/retail',
  },
  'category.news.business': {
    id: 'category.news.business',
    defaultMessage: 'Business',
  },
  'category.news.business.href': {
    id: 'category.news.business.href',
    defaultMessage: '/news/business',
  },
  'category.news.business.param': {
    id: 'category.news.business.param',
    defaultMessage: 'news/business',
  },
  'category.news.culture': {
    id: 'category.news.culture',
    defaultMessage: 'Culture',
  },
  'category.news.culture.href': {
    id: 'category.news.culture.href',
    defaultMessage: '/news/culture',
  },
  'category.news.culture.param': {
    id: 'category.news.culture.param',
    defaultMessage: 'news/culture',
  },
  'category.news.people': {
    id: 'category.news.people',
    defaultMessage: 'People',
  },
  'category.news.people.href': {
    id: 'category.news.people.href',
    defaultMessage: '/news/people',
  },
  'category.news.people.param': {
    id: 'category.news.people.param',
    defaultMessage: 'news/people',
  },
  'category.news.fairs': {
    id: 'category.news.fairs',
    defaultMessage: 'Fairs',
  },
  'category.news.fairs.href': {
    id: 'category.news.fairs.href',
    defaultMessage: '/news/fairs',
  },
  'category.news.fairs.param': {
    id: 'category.news.fairs.param',
    defaultMessage: 'news/fairs',
  },
  'category.news.background': {
    id: 'category.news.background',
    defaultMessage: 'Background',
  },
  'category.news.background.href': {
    id: 'category.news.background.href',
    defaultMessage: '/news/background',
  },
  'category.news.background.param': {
    id: 'category.news.background.param',
    defaultMessage: 'news/background',
  },
  'category.news.video': {
    id: 'category.news.video',
    defaultMessage: 'Video',
  },
  'category.news.video.href': {
    id: 'category.news.video.href',
    defaultMessage: '/news/video',
  },
  'category.news.video.param': {
    id: 'category.news.video.param',
    defaultMessage: 'news/video',
  },
  'category.news.podcast': {
    id: 'category.news.podcast',
    defaultMessage: 'Podcast',
  },
  'category.news.podcast.href': {
    id: 'category.news.podcast.href',
    defaultMessage: '/news/podcast',
  },
  'category.news.podcast.param': {
    id: 'category.news.podcast.param',
    defaultMessage: 'news/podcast',
  },
  'category.education.news': {
    id: 'category.education.news',
    defaultMessage: 'News',
  },
  'category.education.news.href': {
    id: 'category.education.news.href',
    defaultMessage: '/education/news',
  },
  'category.education.schools': {
    id: 'category.education.schools',
    defaultMessage: 'Schools',
  },
  'category.education.schools.href': {
    id: 'category.education.schools.href',
    defaultMessage: '/education/schools',
  },
  'category.executive.report': {
    id: 'category.executive.report',
    defaultMessage: 'Report',
  },
  'category.executive.report.href': {
    id: 'category.executive.report.href',
    defaultMessage: '/executive/report',
  },
  'category.executive.management': {
    id: 'category.executive.management',
    defaultMessage: 'Management',
  },
  'category.executive.management.href': {
    id: 'category.executive.management.href',
    defaultMessage: '/executive/management',
  },
  'category.press': {
    id: 'category.press',
    defaultMessage: 'Press',
  },
  'category.press.href': {
    id: 'category.press.href',
    defaultMessage: '/press',
  },
  'category.press.param': {
    id: 'category.press.param',
    defaultMessage: 'press',
  },
  'category.press.fashion': {
    id: 'category.press.fashion',
    defaultMessage: 'Fashion',
  },
  'category.press.fashion.href': {
    id: 'category.press.fashion.href',
    defaultMessage: '/press/fashion',
  },
  'category.press.retail': {
    id: 'category.press.retail',
    defaultMessage: 'Retail',
  },
  'category.press.retail.href': {
    id: 'category.press.retail.href',
    defaultMessage: '/press/retail',
  },
  'category.press.business': {
    id: 'category.press.business',
    defaultMessage: 'Business',
  },
  'category.press.business.href': {
    id: 'category.press.business.href',
    defaultMessage: '/press/business',
  },
  'category.press.people': {
    id: 'category.press.people',
    defaultMessage: 'People',
  },
  'category.press.people.href': {
    id: 'category.press.people.href',
    defaultMessage: '/press/people',
  },
  'category.press.culture': {
    id: 'category.press.culture',
    defaultMessage: 'Culture',
  },
  'category.press.culture.href': {
    id: 'category.press.culture.href',
    defaultMessage: '/press/culture',
  },
  'category.press.research': {
    id: 'category.press.research',
    defaultMessage: 'Research',
  },
  'category.press.research.href': {
    id: 'category.press.research.href',
    defaultMessage: '/press/research',
  },
  'category.press.celebrity': {
    id: 'category.press.celebrity',
    defaultMessage: 'Celebrity',
  },
  'category.press.celebrity.href': {
    id: 'category.press.celebrity.href',
    defaultMessage: '/press/celebrity',
  },
  'category.press.event': {
    id: 'category.press.event',
    defaultMessage: 'Event',
  },
  'category.press.event.href': {
    id: 'category.press.event.href',
    defaultMessage: '/press/event',
  },
  'category.tags.beauty': {
    id: 'category.tags.beauty',
    defaultMessage: 'Beauty',
  },
  'category.tags.beauty.param': {
    id: 'category.tags.beauty.param',
    defaultMessage: 'beauty',
  },
  'category.tags.bodyFashion': {
    id: 'category.tags.bodyFashion',
    defaultMessage: 'Body Fashion',
  },
  'category.tags.bodyFashion.param': {
    id: 'category.tags.bodyFashion.param',
    defaultMessage: 'body-fashion',
  },
  'category.tags.colours': {
    id: 'category.tags.colours',
    defaultMessage: 'Colours',
  },
  'category.tags.colours.param': {
    id: 'category.tags.colours.param',
    defaultMessage: 'colours',
  },
  'category.tags.denim': {
    id: 'category.tags.denim',
    defaultMessage: 'Denim',
  },
  'category.tags.denim.param': {
    id: 'category.tags.denim.param',
    defaultMessage: 'denim',
  },
  'category.tags.digitalFashion': {
    id: 'category.tags.digitalFashion',
    defaultMessage: 'Digital Fashion',
  },
  'category.tags.digitalFashion.param': {
    id: 'category.tags.digitalFashion.param',
    defaultMessage: 'digital-fashion',
  },
  'category.tags.footwear': {
    id: 'category.tags.footwear',
    defaultMessage: 'Footwear',
  },
  'category.tags.footwear.param': {
    id: 'category.tags.footwear.param',
    defaultMessage: 'footwear',
  },
  'category.tags.kids': {
    id: 'category.tags.kids',
    defaultMessage: 'Kids',
  },
  'category.tags.kids.param': {
    id: 'category.tags.kids.param',
    defaultMessage: 'kids',
  },
  'category.tags.menswear': {
    id: 'category.tags.menswear',
    defaultMessage: 'Menswear',
  },
  'category.tags.menswear.param': {
    id: 'category.tags.menswear.param',
    defaultMessage: 'menswear',
  },
  'category.tags.parade': {
    id: 'category.tags.parade',
    defaultMessage: 'Parade',
  },
  'category.tags.parade.param': {
    id: 'category.tags.parade.param',
    defaultMessage: 'parade',
  },
  'category.tags.season.param': {
    id: 'category.tags.season.param',
    defaultMessage: 'season',
  },
  'category.tags.trends.param': {
    id: 'category.tags.trends.param',
    defaultMessage: 'trends',
  },
  'category.tags.womenswear': {
    id: 'category.tags.womenswear',
    defaultMessage: 'Womenswear',
  },
  'category.tags.womenswear.param': {
    id: 'category.tags.womenswear.param',
    defaultMessage: 'womenswear',
  },
  features: {
    id: 'features',
    defaultMessage: 'Features',
  },
  internationalNetwork: {
    id: 'internationalNetwork',
    defaultMessage: 'International Network',
  },
  careers: {
    id: 'careers',
    defaultMessage: 'Careers',
  },
  conditions: {
    id: 'conditions',
    defaultMessage: 'Conditions',
  },
  info: {
    id: 'info',
    defaultMessage: 'Info',
  },
  'info.features.news': {
    id: 'info.features.news',
    defaultMessage: 'News',
  },
  'info.features.newsletter': {
    id: 'info.features.newsletter',
    defaultMessage: 'Newsletter',
  },
  'info.features.careerCenter': {
    id: 'info.features.careerCenter',
    defaultMessage: 'Career Center',
  },
  'info.features.fashionWeekWeb': {
    id: 'info.features.fashionWeekWeb',
    defaultMessage: 'Fashion Week Web',
  },
  'info.features.media': {
    id: 'info.features.media',
    defaultMessage: 'Media',
  },
  'info.features.businessIntelligence': {
    id: 'info.features.businessIntelligence',
    defaultMessage: 'Business Intelligence',
  },
  'info.features.companyDirectory': {
    id: 'info.features.companyDirectory',
    defaultMessage: 'Company Directory',
  },
  'info.features.education': {
    id: 'info.features.education',
    defaultMessage: 'Education',
  },
  'info.about': {
    id: 'info.about',
    defaultMessage: 'About Us',
  },
  'info.about.mediaReleases': {
    id: 'info.about.mediaReleases',
    defaultMessage: 'Media Releases',
  },
  'info.conditions': {
    id: 'info.conditions',
    defaultMessage: 'Conditions',
  },
  'info.conditions.termsOfUse': {
    id: 'info.conditions.termsOfUse',
    defaultMessage: 'Terms of Use',
  },
  'jobs.category.all-categories.label': {
    id: 'jobs.category.all-categories.label',
    defaultMessage: 'All Categories',
  },
  'jobs.category.all-categories.description': {
    id: 'jobs.category.all-categories.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Looking for fashion jobs or an other challenge in the fashion industry?',
  },
  'jobs.category.all-categories.introduction': {
    id: 'jobs.category.all-categories.introduction',
    // eslint-disable-next-line max-len
    defaultMessage: 'Looking for fashion jobs? Start your new professional life here.',
  },
  'jobs.category.all-categories.title': {
    id: 'jobs.category.all-categories.title',
    defaultMessage: 'Fashion jobs',
  },
  'jobs.category.design-and-creative.param': {
    id: 'jobs.category.design-and-creative.param',
    defaultMessage: 'design-and-creative',
  },
  'jobs.category.design-and-creative.label': {
    id: 'jobs.category.design-and-creative.label',
    defaultMessage: 'Design & Creative',
  },
  'jobs.category.design-and-creative.description': {
    id: 'jobs.category.design-and-creative.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Looking for creative and design jobs in the fashion industry?',
  },
  'jobs.category.design-and-creative.introduction': {
    id: 'jobs.category.design-and-creative.introduction',
    // eslint-disable-next-line max-len
    defaultMessage: 'Looking for fashion design jobs? Start your creative fashion life here.',
  },
  'jobs.category.design-and-creative.title': {
    id: 'jobs.category.design-and-creative.title',
    defaultMessage: 'Creative & design jobs in fashion',
  },
  'jobs.category.internships.param': {
    id: 'jobs.category.internships.param',
    defaultMessage: 'internships',
  },
  'jobs.category.internships.label': {
    id: 'jobs.category.internships.label',
    defaultMessage: 'Internships',
  },
  'jobs.category.internships.description': {
    id: 'jobs.category.internships.description',
    defaultMessage: 'Looking for internships in the fashion industry?',
  },
  'jobs.category.internships.introduction': {
    id: 'jobs.category.internships.introduction',
    // eslint-disable-next-line max-len
    defaultMessage: 'Looking for fashion internships? Start your fashion career here.',
  },
  'jobs.category.internships.title': {
    id: 'jobs.category.internships.title',
    defaultMessage: 'Internships in the fashion industry',
  },
  'jobs.category.other.param': {
    id: 'jobs.category.other.param',
    defaultMessage: 'other',
  },
  'jobs.category.other.label': {
    id: 'jobs.category.other.label',
    defaultMessage: 'Other',
  },
  'jobs.category.other.description': {
    id: 'jobs.category.other.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Looking for fashion jobs or an other challenge in the fashion industry?',
  },
  'jobs.category.other.introduction': {
    id: 'jobs.category.other.introduction',
    defaultMessage: 'Looking for various fashion jobs? Start your career here.',
  },
  'jobs.category.other.title': {
    id: 'jobs.category.other.title',
    defaultMessage: 'Other jobs in fashion',
  },
  'jobs.category.product-and-supply-chain.param': {
    id: 'jobs.category.product-and-supply-chain.param',
    defaultMessage: 'product-and-supply-chain',
  },
  'jobs.category.product-and-supply-chain.label': {
    id: 'jobs.category.product-and-supply-chain.label',
    defaultMessage: 'Product & Supply Chain',
  },
  'jobs.category.product-and-supply-chain.description': {
    id: 'jobs.category.product-and-supply-chain.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Looking for product and supply chain jobs in the fashion industry?',
  },
  'jobs.category.product-and-supply-chain.introduction': {
    id: 'jobs.category.product-and-supply-chain.introduction',
    // eslint-disable-next-line max-len
    defaultMessage: 'Looking for supply chain jobs in fashion? Start your new professional life here.',
  },
  'jobs.category.product-and-supply-chain.title': {
    id: 'jobs.category.product-and-supply-chain.title',
    defaultMessage: 'Product & supply chain jobs in fashion',
  },
  'jobs.category.retail-management-and-in-store.param': {
    id: 'jobs.category.retail-management-and-in-store.param',
    defaultMessage: 'retail-management-and-in-store',
  },
  'jobs.category.retail-management-and-in-store.label': {
    id: 'jobs.category.retail-management-and-in-store.label',
    defaultMessage: 'Retail Management & In-store',
  },
  'jobs.category.retail-management-and-in-store.description': {
    id: 'jobs.category.retail-management-and-in-store.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Looking for retail management and in-store jobs in the fashion industry?',
  },
  'jobs.category.retail-management-and-in-store.introduction': {
    id: 'jobs.category.retail-management-and-in-store.introduction',
    // eslint-disable-next-line max-len
    defaultMessage: 'Looking for retail management & in-store jobs in the fashion industry? Start your fashion career here.',
  },
  'jobs.category.retail-management-and-in-store.title': {
    id: 'jobs.category.retail-management-and-in-store.title',
    defaultMessage: 'Retail management & in-store jobs in fashion',
  },
  'jobs.category.sales-and-marketing.param': {
    id: 'jobs.category.sales-and-marketing.param',
    defaultMessage: 'sales-and-marketing',
  },
  'jobs.category.sales-and-marketing.label': {
    id: 'jobs.category.sales-and-marketing.label',
    defaultMessage: 'Sales & Marketing',
  },
  'jobs.category.sales-and-marketing.description': {
    id: 'jobs.category.sales-and-marketing.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Looking for sales and marketing jobs in the fashion industry?',
  },
  'jobs.category.sales-and-marketing.introduction': {
    id: 'jobs.category.sales-and-marketing.introduction',
    // eslint-disable-next-line max-len
    defaultMessage: 'Looking for sales & marketing jobs in fashion? Start your new commercial fashion life here.',
  },
  'jobs.category.sales-and-marketing.title': {
    id: 'jobs.category.sales-and-marketing.title',
    defaultMessage: 'Sales & marketing jobs in fashion',
  },
  'jobs.relatedTo': {
    id: 'jobs.relatedTo',
    defaultMessage: '{term} jobs',
  },
  'jobs.company.title': {
    id: 'jobs.company.title',
    defaultMessage: '{company} jobs',
  },
  'jobs.company.description': {
    id: 'jobs.company.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Dreaming about finding a job at {company}? Click here to find the latest {company} jobs you can apply for',
  },
  'jobs.city.title': {
    id: 'jobs.city.title',
    defaultMessage: 'Fashion jobs {city}',
  },
  'jobs.city.description': {
    id: 'jobs.city.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Find your fashion job in {city} with FashionUnited, the international fashion job board.',
  },
  'jobs.city.introduction': {
    id: 'jobs.city.introduction',
    // eslint-disable-next-line max-len
    defaultMessage: 'Find the fashion job of your dreams in {city} thanks to FashionUnited. More than 10\'000 career opportunities in various fields',
  },
  'jobs.error.notFound.introduction': {
    id: 'jobs.error.notFound.introduction',
    defaultMessage: 'Please have a look below to find your new dream job',
  },
  'jobs.error.notFound.title': {
    id: 'jobs.error.notFound.title',
    defaultMessage: 'The job you were looking for has already been filled',
  },
  'jobs.position.title': {
    id: 'jobs.position.title',
    defaultMessage: '{position} jobs in fashion',
  },
  'jobs.position.description': {
    id: 'jobs.position.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Looking for a job position in fashion? Check the FashionUnited job board and find job openings for most the popular positions in fashion.',
  },
  'jobs.position.introduction': {
    id: 'jobs.position.introduction',
    // eslint-disable-next-line max-len
    defaultMessage: 'More than 10.000 career opportunities within the different fields of the fashion industry in the FashionUnited network.',
  },
  'jobs.no_jobs_found_criteria': {
    id: 'jobs.no_jobs_found_criteria',
    defaultMessage: 'No jobs found matching this criteria!!',
  },
  'jobs.no_jobs_for_keywords': {
    id: 'jobs.no_jobs_for_keywords',
    defaultMessage: 'Your search {keywords} did not match any of the jobs.',
  },
  location: {
    id: 'location',
    defaultMessage: 'Location',
  },
  oops: {
    id: 'oops',
    defaultMessage: 'Oops!',
  },
  openInNew: {
    id: 'openInNew',
    defaultMessage: 'Open in new window',
  },
  news: {
    id: 'news',
    defaultMessage: 'News',
  },
  'news-feed': {
    id: 'news-feed',
    defaultMessage: 'Just In',
  },
  press: {
    id: 'press',
    defaultMessage: 'Press',
  },
  jobs: {
    id: 'jobs',
    defaultMessage: 'Jobs',
  },
  positions: {
    id: 'positions',
    defaultMessage: 'Positions',
  },
  jobPosition: {
    id: 'jobPosition',
    defaultMessage: 'Job position',
  },
  'jobs-positions.jobs': {
    id: 'jobs-positions.jobs',
    defaultMessage: '{position} jobs',
  },
  numberOfJobs: {
    id: 'numberOfJobs',
    defaultMessage: 'Number of jobs',
  },
  positionsInFashion: {
    id: 'positionsInFashion',
    defaultMessage: 'Positions in Fashion',
  },
  'jobs-positions.title': {
    id: 'jobs-positions.title',
    // eslint-disable-next-line max-len
    defaultMessage: 'List of job openings for the most popular positions in fashion',
  },
  'jobs-positions.introduction': {
    id: 'jobs-positions.introduction',
    // eslint-disable-next-line max-len
    defaultMessage: 'More than 10.000 career opportunities within the different fields of the fashion industry in the FashionUnited network.',
  },
  'jobs-positions.description': {
    id: 'jobs-positions.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Looking for a job position in fashion? Check the FashionUnited job board and find job openings for most the popular positions in fashion.',
  },
  cities: {
    id: 'cities',
    defaultMessage: 'Cities',
  },
  'jobs-cities.jobs': {
    id: 'jobs-cities.jobs',
    defaultMessage: '{city} jobs',
  },
  'jobs-cities.title': {
    id: 'jobs-cities.title',
    defaultMessage: 'Fashion jobs in the world\'s biggest cities',
  },
  'jobs-cities.introduction': {
    id: 'jobs-cities.introduction',
    // eslint-disable-next-line max-len
    defaultMessage: 'More than 10.000 career opportunities in the FashionUnited network.',
  },
  'jobs-cities.description': {
    id: 'jobs-cities.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Browse through more than 10.000 career opportunities in the world\'s biggest cities.',
  },
  workAt: {
    id: 'workAt',
    defaultMessage: 'Work-at',
  },
  company: {
    id: 'company',
    defaultMessage: 'Company',
  },
  companies: {
    id: 'companies',
    defaultMessage: 'Companies',
  },
  'company-profile.contactDetails': {
    id: 'company-profile.contactDetails',
    defaultMessage: 'Contact details',
  },
  'company-profile.websiteUrl': {
    id: 'company-profile.websiteUrl',
    defaultMessage: 'Website url',
  },
  'company-profile.videoUrl': {
    id: 'company-profile.videoUrl',
    defaultMessage: 'Video url',
  },
  'company-profile.about': {
    id: 'company-profile.about',
    defaultMessage: 'Text 1 about',
  },
  'company-profile.b2bPartnerPolicy': {
    id: 'company-profile.b2bPartnerPolicy',
    defaultMessage: 'Text 2 about & contact wholesale details',
  },
  'company-profile.desktopHeaderImage': {
    id: 'company-profile.desktopHeaderImage',
    defaultMessage: 'Desktop Header Image',
  },
  'company-profile.mobileHeaderImage': {
    id: 'company-profile.mobileHeaderImage',
    defaultMessage: 'Mobile Header Image',
  },
  'company-profile.saveProfile': {
    id: 'company-profile.saveProfile',
    defaultMessage: 'Save Profile',
  },
  'company-profile.title': {
    id: 'company-profile.title',
    defaultMessage: '{title} wholesale collection',
  },
  'company-profile.description': {
    id: 'company-profile.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Get all {title} wholesale information on the {title} wholesale collection page here',
  },
  'company-profiles.title': {
    id: 'company-profiles.title',
    defaultMessage: "Jobs in the world's biggest fashion companies",
  },
  'company-profiles.description': {
    id: 'company-profiles.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Dreaming about a career in fashion? More than 10.000 career opportunities are waiting for you on FashionUnited.',
  },
  'company-profiles.introduction': {
    id: 'company-profiles.introduction',
    // eslint-disable-next-line max-len
    defaultMessage: 'More than 10.000 career opportunities in the FashionUnited network.',
  },
  'company-profiles.jobs': {
    id: 'company-profiles.jobs',
    defaultMessage: '{company} jobs and careers',
  },
  'fashion-finder.title': {
    id: 'fashion-finder.title',
    defaultMessage: 'Fashion Finder',
  },
  'fashion-finder.description': {
    id: 'fashion-finder.description',
    defaultMessage: 'Fashion Finder',
  },
  'fashion-finder-profile.title': {
    id: 'fashion-finder-profile.title',
    defaultMessage: '{brandName} Fashion Finder',
  },
  'fashion-finder-profile.description': {
    id: 'fashion-finder-profile.description',
    defaultMessage: '{brandName} Fashion Finder',
  },
  'jobs.href': {
    id: 'jobs.href',
    defaultMessage: '/fashionjobs',
  },
  events: {
    id: 'events',
    defaultMessage: 'events',
  },
  special: {
    id: 'special',
    defaultMessage: 'Special',
  },
  'specials-directory': {
    id: 'specials-directory',
    defaultMessage: 'Education',
  },
  'specials-directory.href': {
    id: 'specials-directory.href',
    defaultMessage: 'https://fashionunited.com/specials',
  },
  'specials.education': {
    id: 'specials.education',
    defaultMessage: 'Education',
  },
  'specials.education.slug': {
    id: 'specials.education.slug',
    defaultMessage: 'education',
  },
  'specials.education.href': {
    id: 'specials.education.href',
    defaultMessage: '/specials/education',
  },
  ss20Title: {
    id: 'ss20Title',
    defaultMessage: 'Spring Summer 2020 fashion trends',
  },
  ss20MetaDescription: {
    id: 'ss20MetaDescription',
    defaultMessage: 'Discover the latest spring summer 2020 fashion trends.',
  },
  data: {
    id: 'data',
    defaultMessage: 'data',
  },
  trends: {
    id: 'trends',
    defaultMessage: 'trends',
  },
  'fashion-week-web': {
    id: 'fashion-week-web',
    defaultMessage: 'Fashion Week Web',
  },
  'fashion-week-web.title': {
    id: 'fashion-week-web.title',
    // eslint-disable-next-line max-len
    defaultMessage: 'Fashion Week New York, Paris, London, Milan, Berlin on Fashion Week Web and many more',
  },
  'fashion-week-web.metaDescription': {
    id: 'fashion-week-web.metaDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'Fashion Week New York, Paris, London, Milan and many more Fashion Weeks from around the world overview by FashionUnited',
  },
  'fashion-week-web.intro': {
    id: 'fashion-week-web.intro',
    defaultMessage: 'Latest Fashion Week News',
  },
  'fashion-week-web.description': {
    id: 'fashion-week-web.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Fashion Week Web is an overview of Fashion Weeks around the globe including news, images and video\'s.',
  },
  newsletter: {
    id: 'newsletter',
    defaultMessage: 'newsletter',
  },
  'newsletter.href': {
    id: 'newsletter.href',
    defaultMessage: '/newsletter/subscribe',
  },
  'newsletterCategory.href': {
    id: 'newsletterCategory.href',
    defaultMessage: '/newsletter/{category}/subscribe',
  },
  'search.href': {
    id: 'search.href',
    defaultMessage: '/search',
  },
  careerInFashion: {
    id: 'careerInFashion',
    defaultMessage: 'Career in Fashion',
  },
  connect: {
    id: 'connect',
    defaultMessage: 'Connect',
  },
  about: {
    id: 'about',
    defaultMessage: 'About',
  },
  fashionNews: {
    id: 'fashionNews',
    defaultMessage: 'Fashion news',
  },
  fashionRetail: {
    id: 'fashionRetail',
    defaultMessage: 'Fashion retail',
  },
  fashionBusiness: {
    id: 'fashionBusiness',
    defaultMessage: 'Fashion business',
  },
  cultureNews: {
    id: 'cultureNews',
    defaultMessage: 'Culture News',
  },
  fashionPeople: {
    id: 'fashionPeople',
    defaultMessage: 'Fashion people',
  },
  fashionShow: {
    id: 'fashionShow',
    defaultMessage: 'Fashion show',
  },
  fashionStatistics: {
    id: 'fashionStatistics',
    defaultMessage: 'Fashion statistics',
  },
  'fashionStatistics.href': {
    id: 'fashionStatistics.href',
    defaultMessage: '/global-fashion-industry-statistics',
  },
  educationNews: {
    id: 'educationNews',
    defaultMessage: 'Education',
  },
  executiveNews: {
    id: 'executiveNews',
    defaultMessage: 'Executive',
  },
  findAFashionJob: {
    id: 'findAFashionJob',
    defaultMessage: 'Find a fashion job',
  },
  'findAFashionJob.href': {
    id: 'findAFashionJob.href',
    defaultMessage: '/fashion-jobs',
  },
  statistics: {
    id: 'statistics',
    defaultMessage: 'Statistics',
  },
  'statistics.href': {
    id: 'statistics.href',
    defaultMessage: 'https://fashionunited.com/global-fashion-industry-statistics',
  },
  businessData: {
    id: 'businessData',
    defaultMessage: 'Business Data',
  },
  'businessData.href': {
    id: 'businessData.href',
    defaultMessage: 'https://fashionunited.com/i',
  },
  businessIntelligence: {
    id: 'businessIntelligence',
    defaultMessage: 'Business Intelligence',
  },
  'businessIntelligence.href': {
    id: 'businessIntelligence.href',
    defaultMessage: 'https://fashionunited.com/i',
  },
  discoverEmployers: {
    id: 'discoverEmployers',
    defaultMessage: 'Discover employers',
  },
  'discoverEmployers.href': {
    id: 'discoverEmployers.href',
    defaultMessage: 'https://fashionunited.com/jobs',
  },
  findAnInternship: {
    id: 'findAnInternship',
    defaultMessage: 'Find an internship',
  },
  'findAnInternship.href': {
    id: 'findAnInternship.href',
    defaultMessage: '/fashion-jobs/internships',
  },
  postAFashionVacancy: {
    id: 'postAFashionVacancy',
    defaultMessage: 'Post a fashion vacancy',
  },
  tradeFairsCalendar: {
    id: 'tradeFairsCalendar',
    defaultMessage: 'Trade Fairs Calendar',
  },
  fashionFinder: {
    id: 'fashionFinder',
    defaultMessage: 'Fashion Finder',
  },
  brandDirectory: {
    id: 'brandDirectory',
    defaultMessage: 'Brand Directory',
  },
  youtube: {
    id: 'youtube',
    defaultMessage: 'Youtube',
  },
  facebook: {
    id: 'facebook',
    defaultMessage: 'Facebook',
  },
  'facebook.href': {
    id: 'facebook.href',
    defaultMessage: 'https://www.facebook.com/FashionUnited',
  },
  linkedin: {
    id: 'linkedin',
    defaultMessage: 'Linkedin',
  },
  instagram: {
    id: 'instagram',
    defaultMessage: 'Instagram',
  },
  fashionUnited: {
    id: 'fashionUnited',
    defaultMessage: 'FashionUnited',
  },
  editorialStatute: {
    id: 'editorialStatute',
    defaultMessage: 'Editorial Statute',
  },
  'editorialStatute.href': {
    id: 'editorialStatute.href',
    defaultMessage: '/editorial-statute',
  },
  'editorialStatute.content': {
    id: 'editorialStatute.content',
    /* eslint-disable-next-line max-len */
    defaultMessage: '<div><p>FashionUnited is an independent platform which aims to inform and share information with the international fashion industry including fashion professionals, media and dedicated fashion industry followers.</p><h2 id="frequency">Frequency</h2><p>FashionUnited publishes daily fashion news on its international websites.</p><h2 id="subjects">Subjects</h2><p>FashionUnited creates independent fashion journalism. The editorial team exercises freedom of subject choice. The team is free to select its own subjects, as well as the way in which information is researched, collated and translated. The subject choice is always – either directly or indirectly – related to the fashion industry. The fashion industry includes all companies which mainly trade in clothing, footwear and accessories, as well as institutions which educate individuals to establish a career within the industry.</p><h2 id="responsibility">Responsibility</h2><p>The editorial team works in accordance with the general valid journalism standards: professional integrity is always maintained by verifying factual truth and by providing balanced arguments and views. The editorial team always endeavour to prevent any unnecessary damage to individuals or companies.</p><h2 id="journalistic-independence">Journalistic independence</h2><p>Published text remains the property of FashionUnited. Invitations for press conferences or trips are accepted without guarantee of publication and content. Interviewees will be given the opportunity to check for inaccuracies before publication but interviewees/third parties will not be able to influence any published article’s angle, structure or tone.</p><p>Read our complete Code of Ethics <a href="/code-of-ethics">here</a>.</p></div>',
  },
  contact: {
    id: 'contact',
    defaultMessage: 'Contact',
  },
  goBack: {
    id: 'goBack',
    defaultMessage: 'Go Back',
  },
  categoryTitle: {
    id: 'categoryTitle',
    defaultMessage: 'Latest Articles in {title}',
  },
  denimDescription: {
    id: 'denimDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'Read all about the latest trends in the denim industry on this special denim trend page.',
  },
  denimTitle: {
    id: 'denimTitle',
    // eslint-disable-next-line max-len
    defaultMessage: 'Discover the latest denim fashion trends and new collections',
  },
  eventsMetaDescription: {
    id: 'eventsMetaDescription',
    /* eslint-disable-next-line max-len */
    defaultMessage: 'Fashion trade shows calendar with fashion events from all around the world. Including business events and Fashion Weeks.',
  },
  eventsTitle: {
    id: 'eventsTitle',
    defaultMessage: 'Fashion trade shows, fairs and apparel events calendar',
  },
  eventsIntro: {
    id: 'eventsIntro',
    /* eslint-disable-next-line max-len */
    defaultMessage: 'Listing of the upcoming fashion industry events such as fashion weeks, trade shows and fairs, for fashion business professionals.',
  },
  indexTitle: {
    id: 'indexTitle',
    /* eslint-disable-next-line max-len */
    defaultMessage: 'Fashion Jobs & Fashion Trade News, Business apparel industry platform',
  },
  indexDescription: {
    id: 'indexDescription',
    /* eslint-disable-next-line max-len */
    defaultMessage: 'Fashion World business platform for the global apparel industry',
  },
  newsFeedTitle: {
    id: 'newsFeedTitle',
    defaultMessage: 'Just In',
  },
  newsFeedPath: {
    id: 'newsFeedPath',
    defaultMessage: 'just-in',
  },
  includePressReleases: {
    id: 'includePressReleases',
    defaultMessage: 'Include Press Releases',
  },
  searchTitle: {
    id: 'searchTitle',
    defaultMessage: 'Search',
  },
  'search.sort.relevanceAndDate': {
    id: 'search.sort.relevanceAndDate',
    defaultMessage: 'Relevance and date (default)',
  },
  'search.sort.relevance': {
    id: 'search.sort.relevance',
    defaultMessage: 'Relevance',
  },
  'search.sort.createdAt': {
    id: 'search.sort.createdAt',
    defaultMessage: 'Date (latest first)',
  },
  'search.sort.createdAtAscending': {
    id: 'search.sort.createdAtAscending',
    defaultMessage: 'Date (oldest first)',
  },
  'search.type.text': {
    id: 'search.type.text',
    defaultMessage: 'Search in text',
  },
  'search.type.ai': {
    id: 'search.type.ai',
    defaultMessage: 'Search with AI',
  },
  tagMetaDescription: {
    id: 'tagMetaDescription',
    defaultMessage: 'Latest {title} news and archive',
  },
  tagTitle: {
    id: 'tagTitle',
    defaultMessage: '{title} news and archive',
  },
  tagsMetaDescription: {
    id: 'tagsMetaDescription',
    /* eslint-disable-next-line max-len */
    defaultMessage: 'The independent fashion news platform and article database, including retail news, news on fashion business, culture, fashion people and industry fairs.',
  },
  tagsTitle: {
    id: 'tagsTitle',
    defaultMessage: 'FashionUnited tags',
  },
  trendsIntro: {
    id: 'trendsIntro',
    defaultMessage: 'Latest Fashion Trends',
  },
  trendsMetaDescription: {
    id: 'trendsMetaDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'Fashion jobs and Fashion news including latest trends, design, a job board for the apparel fashion industry and many fashion and shoe brands for designers.',
  },
  trendsNews: {
    id: 'trendsNews',
    defaultMessage: 'Latest Trends News',
  },
  trendsButton: {
    id: 'trendsButton',
    defaultMessage: 'More Trends',
  },
  trendsFeed: {
    id: 'trendsFeed',
    defaultMessage: 'Daily Trend Feed',
  },
  top100: {
    id: 'top100',
    defaultMessage: 'Top 100',
  },
  eventsSearchPlaceholder: {
    id: 'eventsSearchPlaceholder',
    defaultMessage: 'Search events',
  },
  date: {
    id: 'date',
    defaultMessage: 'Date',
  },
  city: {
    id: 'city',
    defaultMessage: 'City',
  },
  prefix: {
    id: 'prefix',
    defaultMessage: '',
  },
  startUrl: {
    id: 'startUrl',
    defaultMessage: '',
  },
  eventsPath: {
    id: 'eventsPath',
    defaultMessage: 'events',
  },
  searchPath: {
    id: 'searchPath',
    defaultMessage: 'search',
  },
  jobsPath: {
    id: 'jobsPath',
    defaultMessage: 'fashion-jobs',
  },
  'jobsApplyPath.suffix': {
    id: 'jobsApplyPath.suffix',
    defaultMessage: 'apply',
  },
  'events.href': {
    id: 'events.href',
    defaultMessage: '/events',
  },
  'news.href': {
    id: 'news.href',
    defaultMessage: 'https://fashionunited.com',
  },
  'trends.href': {
    id: 'trends.href',
    defaultMessage: '/trends',
  },
  jobsMetaDescription: {
    id: 'jobsMetaDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'The most comprehensive and up-to-date fashion job board for the fashion industry.',
  },
  jobsTitle: {
    id: 'jobsTitle',
    defaultMessage: 'Find your fashion job with FashionUnited',
  },
  jobsWfbTitle: {
    id: 'jobsWfbTitle',
    defaultMessage: 'Search by top brands',
  },
  searchJobs: {
    id: 'searchJobs',
    defaultMessage: 'Search for jobs',
  },
  'searchJobs.href': {
    id: 'searchJobs.href',
    defaultMessage: '/fashion-jobs',
  },
  postJob: {
    id: 'postJob',
    defaultMessage: 'Post a job',
  },
  'postJob.href': {
    id: 'postJob.href',
    defaultMessage: 'https://fashionunited.com/company/submit-your-job-postings',
  },
  'jobsHeading.retail': {
    id: 'jobsHeading.retail',
    defaultMessage: 'RETAIL MANAGEMENT & IN-STORE FASHION JOBS',
  },
  'jobsHeading.retail.jobTitles': {
    id: 'jobsHeading.retail.jobTitles',
    // eslint-disable-next-line max-len
    defaultMessage: 'Store Manager, Sales Associate, (Visual) Merchandiser, Assistant Manager, Supervisor',
  },
  'jobsHeading.retail.href': {
    id: 'jobsHeading.retail.href',
    defaultMessage: '/fashion-jobs/retail-management-and-in-store',
  },
  'jobsHeading.sales': {
    id: 'jobsHeading.sales',
    defaultMessage: 'SALES & MARKETING JOBS IN FASHION',
  },
  'jobsHeading.sales.jobTitles': {
    id: 'jobsHeading.sales.jobTitles',
    // eslint-disable-next-line max-len
    defaultMessage: 'Account Manager, Marketing Manager, Sales Manager, E-Commerce, Merchandising',
  },
  'jobsHeading.sales.href': {
    id: 'jobsHeading.sales.href',
    defaultMessage: '/fashion-jobs/sales-and-marketing',
  },
  'jobsHeading.design': {
    id: 'jobsHeading.design',
    defaultMessage: 'DESIGN & CREATIVE JOBS IN FASHION',
  },
  'jobsHeading.design.jobTitles': {
    id: 'jobsHeading.design.jobTitles',
    // eslint-disable-next-line max-len
    defaultMessage: 'Designer, Graphic Designer, Machinist, Pattern Cutter, Stylist',
  },
  'jobsHeading.design.href': {
    id: 'jobsHeading.design.href',
    defaultMessage: '/fashion-jobs/design-and-creative',
  },
  'jobsHeading.product': {
    id: 'jobsHeading.product',
    defaultMessage: 'PRODUCT & SUPPLY CHAIN JOBS IN FASHION',
  },
  'jobsHeading.product.jobTitles': {
    id: 'jobsHeading.product.jobTitles',
    // eslint-disable-next-line max-len
    defaultMessage: 'Buyer, Production Coordinator, Quality Controller, Supply Chain Manager',
  },
  'jobsHeading.product.href': {
    id: 'jobsHeading.product.href',
    defaultMessage: '/fashion-jobs/fashion-jobs/product-and-supply-chain',
  },
  'jobsHeading.other': {
    id: 'jobsHeading.other',
    defaultMessage: 'OTHER JOBS IN FASHION',
  },
  'jobsHeading.other.jobTitles': {
    id: 'jobsHeading.other.jobTitles',
    // eslint-disable-next-line max-len
    defaultMessage: 'Receptionist, Accountant, Finance Manager, Editor, Office Manager, HR Manager',
  },
  'jobsHeading.other.href': {
    id: 'jobsHeading.other.href',
    defaultMessage: '/fashion-jobs/other',
  },
  'jobsHeading.internships': {
    id: 'jobsHeading.internships',
    defaultMessage: 'INTERNSHIPS & TRAINEESHIPS',
  },
  'jobsHeading.internships.jobTitles': {
    id: 'jobsHeading.internships.jobTitles',
    // eslint-disable-next-line max-len
    defaultMessage: 'design, product development, marketing, social media, merchandising, editorial',
  },
  'jobsHeading.internships.href': {
    id: 'jobsHeading.internships.href',
    defaultMessage: '/fashion-jobs/internships',
  },
  'jobsHeading.international': {
    id: 'jobsHeading.international',
    defaultMessage: 'INTERNATIONAL JOBS IN FASHION',
  },
  'jobsHeading.international.jobTitles': {
    id: 'jobsHeading.international.jobTitles',
    defaultMessage: 'Search jobs in over 20 countries worldwide',
  },
  'jobsHeading.international.href': {
    id: 'jobsHeading.international.href',
    defaultMessage: 'https://fashionunited.com/landing/international-fashion-jobs',
  },
  'jobsAction.newsletter': {
    id: 'jobsAction.newsletter',
    defaultMessage: 'NEWSLETTER',
  },
  'jobsAction.newsletter.subTitle': {
    id: 'jobsAction.newsletter.subTitle',
    defaultMessage: 'Subscribe for free',
  },
  'jobsAction.newsletter.href': {
    id: 'jobsAction.newsletter.href',
    defaultMessage: '/newsletter/subscribe',
  },
  'jobsAction.newsletter.useRouter': {
    id: 'jobsAction.newsletter.useRouter',
    defaultMessage: false,
  },
  'jobsAction.newsletter.route': {
    id: 'jobsAction.newsletter.route',
    defaultMessage: '/newsletter/subscribe',
  },
  'jobsAction.employers': {
    id: 'jobsAction.employers',
    defaultMessage: 'DISCOVER EMPLOYERS',
  },
  'jobsAction.employers.subTitle': {
    id: 'jobsAction.employers.subTitle',
    defaultMessage: 'Find the perfect fit',
  },
  'jobsAction.employers.href': {
    id: 'jobsAction.employers.href',
    defaultMessage: 'https://fashionunited.com/fashion-jobs/work-at',
  },
  'jobsAction.employers.useRouter': {
    id: 'jobsAction.employers.useRouter',
    defaultMessage: true,
  },
  'jobsAction.employers.route': {
    id: 'jobsAction.employers.route',
    defaultMessage: 'company-profiles.en-US',
  },
  'jobsAction.education': {
    id: 'jobsAction.education',
    defaultMessage: 'EDUCATION NETWORK',
  },
  'jobsAction.education.subTitle': {
    id: 'jobsAction.education.subTitle',
    defaultMessage: 'Bridging the gap in the industry',
  },
  'jobsAction.education.href': {
    id: 'jobsAction.education.href',
    defaultMessage: 'https://fashionunited.com/education',
  },
  'jobsAction.education.useRouter': {
    id: 'jobsAction.education.useRouter',
    defaultMessage: false,
  },
  'jobsAction.education.route': {
    id: 'jobsAction.education.route',
    defaultMessage: 'courses',
  },
  'jobsAction.careers': {
    id: 'jobsAction.careers',
    defaultMessage: 'WORK IN FASHION',
  },
  'jobsAction.careers.subTitle': {
    id: 'jobsAction.careers.subTitle',
    defaultMessage: 'Latest news and opportunities',
  },
  'jobsAction.careers.href': {
    id: 'jobsAction.careers.href',
    defaultMessage: 'https://fashionunited.com/specials/work-in-fashion',
  },
  'jobsAction.careers.useRouter': {
    id: 'jobsAction.careers.useRouter',
    defaultMessage: false,
  },
  'jobsAction.careers.route': {
    id: 'jobsAction.careers.route',
    defaultMessage: 'specials/work-in-fashion',
  },
  careersTitle: {
    id: 'careersTitle',
    defaultMessage: 'Fashion Careers',
  },
  careersMetaDescription: {
    id: 'careersMetaDescription',
    defaultMessage: 'Discover the latest Career fashion trends.',
  },
  fw20Title: {
    id: 'fw20Title',
    defaultMessage: 'Herfst/winter modetrends 2020',
  },
  fw20MetaDescription: {
    id: 'fw20MetaDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'Dit zijn de laatste nieuwtjes over de modetrends van herfst en winter 2020/2021.',
  },
  coronaVirusTitle: {
    id: 'coronaVirusTitle',
    defaultMessage: 'Impact of Covid-19 / Coronavirus on fashion industry',
  },
  coronaVirusDesription: {
    id: 'coronaVirusDesription',
    // eslint-disable-next-line max-len
    defaultMessage: 'On this page FashionUnited publishes the latest updates about Covid-19 / Coronavirus. Please send relevant updates to tip@fashionunited.com. Most positive coronavirus news currently comes from China. CCCT, the Chinese Textile and Apparel chamber of commerce, reports a positive trend in the resumption of operations in textile and apparel sectors. The capacity of production moved towards 80 percent of the normal situation. Logistics and port facilities are recovering. After surfacing in Wuhan late 2019, the draconian measures have worked even in Wuhan, and slowly but surely bring business back to normal. With Italy following China\'s measures effect is expected in the next two weeks. Major apparel production countries, India, Bangladesh, Turkey, Portugal and Morocco are operating at full capacity. Most other countries have taken Covid-19 seriously and are taking precautions to contain the virus to prevent Chinese and Italian scenario\'s.',
  },
  pressReleaseMessage: {
    id: 'pressReleaseMessage',
    defaultMessage: 'PRESS RELEASE',
  },
  socialMediaTitle: {
    id: 'socialMediaTitle',
    defaultMessage: 'Social Media in Fashion',
  },
  socialMediaDescription: {
    id: 'socialMediaDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'Read all about the many Social Media trends in the fashion industry.',
  },
  fw23Title: {
    id: 'fw23Title',
    defaultMessage: 'Autumn Winter 2023 fashion trends and new collections',
  },
  fw23Description: {
    id: 'fw23Description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Discover the latest autumn winter 2023 fashion trends and the new collections for the season. Many fashion brands have launched their winter 2023 collection, read about them in the trend news below. Various trend forecasters report about the autumn winter 2023 fashion trend forecasts including colors, fabrics, silhouettes, patterns, styles, and more for clothing collections in upcoming seasons. You will find trend information for womenswear, menswear, childrenswear, shoes and accessories.',
  },
  articleBy: {
    id: 'articleBy',
    defaultMessage: 'By {author}',
  },
  allArticlesBy: {
    id: 'allArticlesBy',
    defaultMessage: 'Articles by {author}',
  },
  startupsTitle: {
    id: 'startupsTitle',
    defaultMessage: 'Fashion Startups new technology',
  },
  startupsDescription: {
    id: 'startupsDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'There is a growing need for smart technology in the fashion industry and for new business models. Read all about fashion startups here.',
  },
  cookiesStatement: {
    id: 'cookiesStatement',
    // eslint-disable-next-line max-len
    defaultMessage: 'This site uses cookies to deliver its services, to personalise ads and to analyse traffic. If you continue browsing the site, you agree to ',
  },
  cookieDescriptionLinkText: {
    id: 'cookieDescriptionLinkText',
    defaultMessage: 'our use of cookies',
  },
  learnMore: {
    id: 'learnMore',
    defaultMessage: 'Learn more',
  },
  gotIt: {
    id: 'gotIt',
    defaultMessage: 'Got it',
  },
  'applicantForm.button': {
    id: 'applicantForm.button',
    defaultMessage: 'Submit',
  },
  'applicantForm.disclaimer': {
    id: 'applicantForm.disclaimer',
    defaultMessage: "<small><em>Privacy statement: </em> FashionUnited values your trust in its services and is committed to maintaining that trust and respecting your privacy. FashionUnited ensures that information is gathered for purposes related to FashionUnited services, will only be used by FashionUnited and will not be sold to third parties. When applying for a job, the Company name and country is clearly stated before you send this third party company your information. You may, at any time, contact FashionUnited to view, update, modify or remove your personal information from the FashionUnited database and restrict our processing of it. For more information see the <a href='https://fashionunited.info/privacy'>Privacy Policy</a></small>",
  },
  'applicantForm.title': {
    id: 'applicantForm.title',
    defaultMessage: 'Apply here to become the next',
  },
  'applicantForm.applyButton': {
    id: 'applicantForm.applyButton',
    defaultMessage: 'APPLY HERE',
  },
  'applicantForm.coverLetterLabel': {
    id: 'applicantForm.coverLetterLabel',
    defaultMessage: 'Cover Letter',
  },
  'applicantForm.cvLabel': {
    id: 'applicantForm.cvLabel',
    defaultMessage: 'CV/Resume',
  },
  'applicantForm.extraDocument1Label': {
    id: 'applicantForm.extraDocument1Label',
    defaultMessage: 'Extra Document',
  },
  'applicantForm.extraDocument2Label': {
    id: 'applicantForm.extraDocument2Label',
    defaultMessage: 'Extra Document',
  },
  'applicantForm.subscribedToNewsletterLabel': {
    id: 'applicantForm.subscribedToNewsletterLabel',
    defaultMessage: 'Keep me updated on the latest industry news',
  },
  'applicantForm.motivationHelpText': {
    id: 'applicantForm.motivationHelpText',
    defaultMessage: 'Enter your motivation manually or upload a cover letter',
  },
  'applicantForm.allowedFileFormats': {
    id: 'applicantForm.allowedFileFormats',
    defaultMessage:
      '(.pdf, .txt, .doc, .docx, .rtf, .odt, .jpg, .jpeg, .png - 6 MB)',
  },
  'applicantForm.noFileSelected': {
    id: 'applicantForm.noFileSelected',
    defaultMessage: 'No file selected',
  },
  'applicantForm.fieldRequired': {
    id: 'applicantForm.fieldRequired',
    defaultMessage: 'This field is required',
  },
  'applicantForm.fileIsEmpty': {
    id: 'applicantForm.fileIsEmpty',
    defaultMessage: 'File must not be empty',
  },
  'applicantForm.fileHasInvalidType': {
    id: 'applicantForm.fileHasInvalidType',
    defaultMessage: 'File must have valid type',
  },
  'applicantForm.fileMaxSizeExceeded': {
    id: 'applicantForm.fileMaxSizeExceeded',
    defaultMessage: 'File must be less than 6MB',
  },
  'applicantForm.mustBeValidEmailAddress': {
    id: 'applicantForm.mustBeValidEmailAddress',
    defaultMessage: 'This field must be a valid email address',
  },
  'applicantForm.applicationSent': {
    id: 'applicantForm.applicationSent',
    defaultMessage: 'The application has been sent.',
  },
  'applicantForm.backToJobBoard': {
    id: 'applicantForm.backToJobBoard',
    defaultMessage: 'You will now return to the job board.',
  },
  thankYou: {
    id: 'thankYou',
    defaultMessage: 'Thank You',
  },
  'jobView.JobTitle': {
    id: 'jobView.JobTitle',
    defaultMessage: 'Job Title',
  },
  'jobView.category': {
    id: 'jobView.category',
    defaultMessage: 'Category',
  },
  'jobView.companyName': {
    id: 'jobView.companyName',
    defaultMessage: 'Company',
  },
  'jobView.contactEmail': {
    id: 'jobView.contactEmail',
    defaultMessage: 'Contact Email',
  },
  'jobView.contactName': {
    id: 'jobView.contactName',
    defaultMessage: 'Contact Name',
  },
  'jobView.contactPhone': {
    id: 'jobView.contactPhone',
    defaultMessage: 'Phone Number',
  },
  'jobView.regionName': {
    id: 'jobView.regionName',
    defaultMessage: 'Region',
  },
  'jobView.countryName': {
    id: 'jobView.countryName',
    defaultMessage: 'Country',
  },
  'jobView.datePost': {
    id: 'jobView.datePost',
    defaultMessage: 'Date Posted',
  },
  'jobView.howToApply': {
    id: 'jobView.howToApply',
    defaultMessage: 'Refer to',
  },
  'jobView.location': {
    id: 'jobView.location',
    defaultMessage: 'Location',
  },
  'jobView.ebpCallout': {
    id: 'jobView.ebpCallout',
    defaultMessage: 'Want to know more about working at {companyName}?',
  },
  'jobView.ebpLink': {
    id: 'jobView.ebpLink',
    defaultMessage: 'Visit the Employer Page',
  },
  'archivedJobs.search': {
    id: 'archivedJobs.search',
    defaultMessage: 'Show more jobs',
  },
  'archivedJobs.header': {
    id: 'archivedJobs.header',
    defaultMessage: 'This job is not accepting any more applications',
  },
  'archivedJobs.findSimilar': {
    id: 'archivedJobs.findSimilar',
    defaultMessage: 'Or find similar job opportunities in:',
  },
  apply_button_text: {
    id: 'apply_button_text',
    defaultMessage: 'Apply Here',
  },
  fashionJobs: {
    id: 'fashionJobs',
    defaultMessage: 'Fashion Jobs',
  },
  'jobs.returnToList': {
    id: 'jobs.returnToList',
    defaultMessage: 'Return to Job List',
  },
  educationTitle: {
    id: 'educationTitle',
    defaultMessage: 'Mode opleidingen in Nederland',
  },
  educationMetaDescription: {
    id: 'educationMetaDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'Nederland heeft diverse modeopleidingen met betrekking tot de verschillende activiteiten in de modebranche. Hier vind je de laatste nieuws over modeonderwijs.',
  },
  educationIntro: {
    id: 'educationIntro',
    defaultMessage: 'The Fashion Education Network',
  },
  educationDescription: {
    id: 'educationDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'Connecting education and industry by providing news, internships and directories.',
  },
  educationPageMetaDescription: {
    id: 'educationPageMetaDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'The Fashion Education Network is a non-profit initiative which offers fashion schools and institutes a platform to stimulate  interaction and knowledge.',
  },
  executiveTitle: {
    id: 'executiveTitle',
    // eslint-disable-next-line max-len
    defaultMessage: 'Fashion Executives Network, fashion Industry Management news',
  },
  executiveMetaDescription: {
    id: 'executiveMetaDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'Find the latest Fashion Trade news for Fashion Executives around the world. This platform provides the latest executive industry news and  statistics.',
  },
  executiveIntro: {
    id: 'executiveIntro',
    defaultMessage: 'The Fashion Executives Network',
  },
  executiveDescription: {
    id: 'executiveDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'The most comprehensive provider of market data and analysis.',
  },
  executiveNewsletterDescription: {
    id: 'executiveNewsletterDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'Join the fashion community of entrepreneurs, CEOs, designers, buyers and other fashion professionals already receiving the FashionUnited newsletter.',
  },
  'products.clearResults': {
    id: 'products.clearResults',
    defaultMessage: 'Reset filters',
  },
  'products.seeResults': {
    id: 'products.seeResults',
    defaultMessage: 'See {resultsCount} results',
  },
  'products.showAll': {
    id: 'products.showAll',
    defaultMessage: 'Show all',
  },
  'products.filter': {
    id: 'products.filter',
    defaultMessage: 'Filter',
  },
  'products.sort': {
    id: 'products.sort',
    defaultMessage: 'Sort',
  },
  'products.buyNow': {
    id: 'products.buyNow',
    defaultMessage: 'Buy {retailer}',
  },
  'products.brands': {
    id: 'products.brands',
    defaultMessage: 'Brands',
  },
  'products.department': {
    id: 'products.department',
    defaultMessage: 'Department',
  },
  'products.sizes': {
    id: 'products.sizes',
    defaultMessage: 'Sizes',
  },
  'products.colors': {
    id: 'products.colors',
    defaultMessage: 'Colors',
  },
  'products.seasons': {
    id: 'products.seasons',
    defaultMessage: 'Seasons',
  },
  'products.genders': {
    id: 'products.genders',
    defaultMessage: 'Gender',
  },
  'products.ageGroups': {
    id: 'products.ageGroups',
    defaultMessage: 'Age Group',
  },
  'products.price': {
    id: 'products.price',
    defaultMessage: 'Price',
  },
  'products.orderQuantity': {
    id: 'products.orderQuantity',
    defaultMessage: 'Order Quantity',
  },
  'products.deliveryTime': {
    id: 'products.deliveryTime',
    defaultMessage: 'Delivery Time',
  },
  'products.deliveryTwoWeeks': {
    id: 'products.deliveryTwoWeeks',
    defaultMessage: 'Delivered within 2 weeks',
  },
  'products.deliveryFiveWeeks': {
    id: 'products.deliveryFiveWeeks',
    defaultMessage: 'Delivered within 5 weeks',
  },
  'products.deliveryTwoMonths': {
    id: 'products.deliveryTwoMonths',
    defaultMessage: 'Delivered within 2 months',
  },
  'products.modelImages': {
    id: 'products.modelImages',
    defaultMessage: 'Model photos',
  },
  'products.imageWithModel': {
    id: 'products.imageWithModel',
    defaultMessage: 'Only show products featuring model photos',
  },
  'products.imageWithoutModel': {
    id: 'products.imageWithoutModel',
    defaultMessage: 'Show products having photos without a model',
  },
  'products.prints': {
    id: 'products.prints',
    defaultMessage: 'Prints',
  },
  'products.materials': {
    id: 'products.materials',
    defaultMessage: 'Materials',
  },
  'products.moreBrands': {
    id: 'products.moreBrands',
    defaultMessage: 'More Brands',
  },
  'product.gender': {
    id: 'product.gender',
    defaultMessage: 'Gender',
  },
  'product.ageGroup': {
    id: 'product.ageGroup',
    defaultMessage: 'Age Group',
  },
  'product.orderQuantity': {
    id: 'product.orderQuantity',
    defaultMessage: 'Order Quantity',
  },
  'product.deliveryTime': {
    id: 'product.deliveryTime',
    defaultMessage: 'Delivery Time',
  },
  'product.colors': {
    id: 'product.colors',
    defaultMessage: 'Colors',
  },
  'product.material': {
    id: 'product.material',
    defaultMessage: 'Material',
  },
  'product.season': {
    id: 'product.season',
    defaultMessage: 'Season',
  },
  'product.brandName': {
    id: 'product.brandName',
    defaultMessage: 'Brand name',
  },
  'product.pattern': {
    id: 'product.pattern',
    defaultMessage: 'Pattern',
  },
  'product.colorName': {
    id: 'product.colorName',
    defaultMessage: 'Color name',
  },
  'product.gtin': {
    id: 'product.gtin',
    defaultMessage: 'GTIN',
  },
  'product.ageGroups': {
    id: 'product.ageGroups',
    defaultMessage: 'Age group',
  },
  'product.sizes': {
    id: 'product.sizes',
    defaultMessage: 'Sizes',
  },
  'product.price': {
    id: 'product.price',
    defaultMessage: 'Price',
  },
  'product.shippingCost': {
    id: 'product.shippingCost',
    defaultMessage: 'Shipping cost',
  },
  'product.backToProducts': {
    id: 'product.backToProducts',
    defaultMessage: 'Back to overview',
  },
  'product.buyAt': {
    id: 'product.buyAt',
    defaultMessage: 'Buy at {retailerName}',
  },
  'product.contact': {
    id: 'product.contact',
    defaultMessage: 'Contact Brand',
  },
  continue: {
    id: 'continue',
  },
  'login.continue': {
    id: 'login.continue',
    defaultMessage: 'Continue',
  },
  forgotPassword: {
    id: 'forgotPassword',
    defaultMessage: 'Forgot Password?',
  },
  'login.title': {
    id: 'login.title',
    defaultMessage: 'Log in to FashionUnited to continue',
  },
  login: {
    id: 'login',
    defaultMessage: 'Log In',
  },
  logout: {
    id: 'logout',
    defaultMessage: 'Log out',
  },
  'register.createAccount': {
    id: 'register.createAccount',
    defaultMessage: 'Create Account',
  },
  editProfile: {
    id: 'editProfile',
    defaultMessage: 'Edit Profile',
  },
  requestAccess: {
    id: 'requestAccess',
    defaultMessage: 'Request Access',
  },
  updateProfile: {
    id: 'updateProfile',
    defaultMessage: 'Update Profile',
  },
  profile: {
    id: 'profile',
    defaultMessage: 'Profile',
  },
  'profile.accountDetails': {
    id: 'profile.accountDetails',
    defaultMessage: 'Account Details',
  },
  'profile.memberSince': {
    id: 'profile.memberSince',
    defaultMessage: 'Member since',
  },
  'profile.myCompany': {
    id: 'profile.myCompany',
    defaultMessage: `My {companyCount, plural,
      one {Company}
      other {Companies}
    }`,
  },
  'profile.myMarkets': {
    id: 'profile.myMarkets',
    defaultMessage: 'My Markets',
  },
  marketplace: {
    id: 'marketplace',
    defaultMessage: 'Marketplace',
  },
  'markets.title': {
    id: 'markets.title',
    defaultMessage: 'FashionUnited B2B Marketplace',
  },
  'markets.description': {
    id: 'markets.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'A B2B Marketplace providing apparel buyers with unique trend insights and brand collection overviews.',
  },
  'market.title': {
    id: 'market.title',
    defaultMessage: '{name} - FashionUnited B2B Marketplace',
  },
  'stands.title': {
    id: 'stands.title',
    defaultMessage: '{name} stands - FashionUnited B2B Marketplace',
  },
  'stands.headingTitle': {
    id: 'stands.headingTitle',
    defaultMessage: '{name} stands',
  },
  'stand.title': {
    id: 'stand.title',
    defaultMessage: '{name} @ {marketName} - FashionUnited B2B Marketplace',
  },
  'brands.title': {
    id: 'brands.title',
    defaultMessage: '{name} brands - FashionUnited B2B Marketplace',
  },
  'brands.headingTitle': {
    id: 'brands.headingTitle',
    defaultMessage: '{name} brands',
  },
  'products.market.title': {
    id: 'products.market.title',
    defaultMessage: '{name} products - FashionUnited B2B Marketplace',
  },
  'products.market.headingTitle': {
    id: 'products.market.headingTitle',
    defaultMessage: '{name} products',
  },
  'products.stand.title': {
    id: 'products.stand.title',
    defaultMessage:
      '{name} @ {marketName} products - FashionUnited B2B Marketplace',
  },
  'products.stand.headingTitle': {
    id: 'products.stand.headingTitle',
    defaultMessage: '{name} products',
  },
  products: {
    id: 'products',
    defaultMessage: 'Products',
  },
  brands: {
    id: 'brands',
    defaultMessage: 'Brands',
  },
  stands: {
    id: 'stands',
    defaultMessage: 'Stands',
  },
  home: {
    id: 'home',
    defaultMessage: 'Home',
  },
  privacy: {
    id: 'privacy',
    defaultMessage: 'Privacy',
  },
  termsOfUse: {
    id: 'termsOfUse',
    defaultMessage: 'Terms of Use',
  },
  signup: {
    id: 'signup',
    defaultMessage: 'Sign Up',
  },
  'register.title': {
    id: 'register.title',
    defaultMessage: 'Register with FashionUnited',
  },
  'register.alreadyRegistered': {
    id: 'register.alreadyRegistered',
    defaultMessage: 'Already have an account?',
  },
  'register.passwordTooShort': {
    id: 'register.passwordTooShort',
    defaultMessage: 'Your password must be at least 12 characters long',
  },
  'register.successHeadline': {
    id: 'register.successHeadline',
    defaultMessage: 'You have successfully registered.',
  },
  'register.successMessage': {
    id: 'register.successMessage',
    // eslint-disable-next-line max-len
    defaultMessage: 'Please check your email inbox to verify your email address.',
  },
  'register.successRedirect': {
    id: 'register.successRedirect',
    defaultMessage: 'You will be redirected to the login page.',
  },
  'register.notABuyer': {
    id: 'register.notABuyer',
    defaultMessage: 'Not affiliated with a company? ',
  },
  'register.basicRegistration': {
    id: 'register.basicRegistration',
    defaultMessage: 'Basic registration',
  },
  'register.emailNotVerified': {
    id: 'register.emailNotVerified',
    // eslint-disable-next-line max-len
    defaultMessage: 'It looks like you haven\'t verified your email yet. Please check your email inbox for further instructions.',
  },
  resendEmail: {
    id: 'resendEmail',
    defaultMessage: 'Resend Email',
  },
  emailResent: {
    id: 'emailResent',
    // eslint-disable-next-line max-len
    defaultMessage: 'Verification email successfully resent. Please check your email inbox to verify your email address.',
  },
  errorRequestingEmail: {
    id: 'errorRequestingEmail',
    // eslint-disable-next-line max-len
    defaultMessage: 'An error has occurred. Please try requesting a verification email again later.',
  },
  register: {
    id: 'register',
    defaultMessage: 'Register',
  },
  resetPassword: {
    id: 'resetPassword',
    defaultMessage: 'Reset Password',
  },
  password: {
    id: 'password',
    defaultMessage: 'Password',
  },
  'resetPassword.title': {
    id: 'resetPassword.title',
    defaultMessage: 'Reset your password',
  },
  'resetPassword.successHeadline': {
    id: 'resetPassword.successHeadline',
    defaultMessage: 'We have received your request.',
  },
  'resetPassword.successMessage': {
    id: 'resetPassword.successMessage',
    defaultMessage: 'Please check your email to reset your password.',
  },
  'resetPassword.mustBeValidEmail': {
    id: 'resetPassword.mustBeValidEmail',
    defaultMessage: 'Must be a valid email address',
  },
  'resetPassword.enterEmail': {
    id: 'resetPassword.enterEmail',
    defaultMessage: 'Enter your email address',
  },
  'resetPassword.returnToLogin': {
    id: 'resetPassword.returnToLogin',
    defaultMessage: 'Return to the login page',
  },
  'resetPassword.sendLink': {
    id: 'resetPassword.sendLink',
    defaultMessage: 'Send Reset Link',
  },
  'changePassword.title': {
    id: 'changePassword.title',
    defaultMessage: 'Change your password',
  },
  'changePassword.newPassword': {
    id: 'changePassword.newPassword',
    defaultMessage: 'Choose a new password',
  },
  'changePassword.successHeadline': {
    id: 'changePassword.successHeadline',
    defaultMessage: 'Success',
  },
  'changePassword.successMessage': {
    id: 'changePassword.successMessage',
    defaultMessage: 'You may now log in with your new password.',
  },
  'changePassword.successRedirect': {
    id: 'changePassword.successRedirect',
    defaultMessage: 'You will be redirected to the login page.',
  },
  mustBeValidUrl: {
    id: 'mustBeValidUrl',
    defaultMessage: 'Please enter a valid url.',
  },
  submit: {
    id: 'submit',
    defaultMessage: 'Submit',
  },
  currencyCode: {
    id: 'currencyCode',
    defaultMessage: 'USD',
  },
  moreProductsAvailable: {
    id: 'moreProductsAvailable',
    defaultMessage: 'There are products that you cannot view.',
  },
  moreProductsAvailableAfterLogin: {
    id: 'moreProductsAvailableAfterLogin',
    // eslint-disable-next-line max-len
    defaultMessage: 'There are products that you cannot view because you are not logged in.',
  },
  moreProductsAvailableAfterUpdate: {
    id: 'moreProductsAvailableAfterUpdate',
    // eslint-disable-next-line max-len
    defaultMessage: 'There are products that you cannot view. To request buyer access you must first complete your user profile, then use the request access button.',
  },
  moreProductsAvailableSuccess: {
    id: 'moreProductsAvailableSuccess',
    // eslint-disable-next-line max-len
    defaultMessage: 'We have successfully received your access request. A FashionUnited employee will check your application. You will receive an email within the next few days when your request is approved.',
  },
  moreProductsAvailablePending: {
    id: 'moreProductsAvailablePending',
    // eslint-disable-next-line max-len
    defaultMessage: 'Your access request is currently under review. You will receive an email within the next few days when your request is approved.',
  },
  completeBuyerProfile: {
    id: 'completeBuyerProfile',
    // eslint-disable-next-line max-len
    defaultMessage: 'Fill in the missing fields below to complete your Marketplace buyer account.',
  },
  errorWhileRequestingAccess: {
    id: 'errorWhileRequestingAccess',
    defaultMessage: 'Error while requesting access!',
  },
  education: {
    id: 'education',
    defaultMessage: 'Education',
  },
  executive: {
    id: 'executive',
    defaultMessage: 'Executive',
  },
  rss: {
    id: 'rss',
    defaultMessage: 'RSS',
  },
  rssFeed: {
    id: 'rssFeed',
    defaultMessage: 'RSS Feed',
  },
  ethics: {
    id: 'ethics',
    defaultMessage: 'Ethics',
  },
  loading: {
    id: 'loading',
    defaultMessage: 'loading...',
  },
  searchLabel: {
    id: 'searchLabel',
    defaultMessage: 'Search {pageTitle}',
  },
  'brands-directory.title': {
    id: 'brands-directory.title',
    defaultMessage: 'International Fashion Brands Directory',
  },
  'brands-directory.description': {
    id: 'brands-directory.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'The global fashion brand directory with thousands of carefully selected apparel brands listed per category.',
  },
  'brands-directory.all-countries.label': {
    id: 'brands-directory.all-countries.label',
    defaultMessage: 'All Countries',
  },
  'brands-directory-with-industry.title': {
    id: 'brands-directory-with-industry.title',
    defaultMessage: '{industries} Brands Directory',
  },
  'company-profile-products.title': {
    id: 'company-profile-products.title',
    defaultMessage: '{title} wholesale collection products',
  },
  'company-profile.editProfile': {
    id: 'company-profile.editProfile',
    defaultMessage: 'Edit Profile',
  },
  firstName: {
    id: 'firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'lastName',
    defaultMessage: 'Last name',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  emailAddress: {
    id: 'emailAddress',
    defaultMessage: 'Email address',
  },
  country: {
    id: 'country',
    defaultMessage: 'Country',
  },
  language: {
    id: 'language',
    defaultMessage: 'Language',
  },
  companyName: {
    id: 'companyName',
    defaultMessage: 'Company name',
  },
  website: {
    id: 'website',
    defaultMessage: 'Website',
  },
  websiteUrl: {
    id: 'websiteUrl',
    defaultMessage: 'Website URL',
  },
  chamberOfCommerce: {
    id: 'chamberOfCommerce',
    defaultMessage: 'Chamber of Commerce',
  },
  chamberOfCommerceNumber: {
    id: 'chamberOfCommerceNumber',
    defaultMessage: 'Chamber of Commerce number',
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Edit',
  },
  save: {
    id: 'save',
    defaultMessage: 'Save',
  },
  'edit-user-profile.title': {
    id: 'edit-user-profile.title',
    defaultMessage: 'Edit your profile',
  },
  'company-profile-edit.title': {
    id: 'company-profile-edit.title',
    defaultMessage: 'Edit {title}',
  },
  buyNow: {
    id: 'buyNow',
    defaultMessage: 'Buy now',
  },
  'companies-directory.title': {
    id: 'companies-directory.title',
    defaultMessage: 'Fashion Company Directory',
  },
  'companies-directory.description': {
    id: 'companies-directory.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'The Fashion Company Directory gathers information of the top fashion companies. Bringing the latest news, jobs offers and stock information on one page.',
  },
  'schools-directory.title': {
    id: 'schools-directory.title',
    defaultMessage: 'Fashion School Directory',
  },
  'schools-directory.description': {
    id: 'schools-directory.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Find fashion schools and courses in the international Fashion School Database. Browse through more than 500 courses in fashion from over 70 fashion schools.',
  },
  'federations-directory.title': {
    id: 'federations-directory.title',
    defaultMessage: 'Fashion Federations Directory',
  },
  'federations-directory.description': {
    id: 'federations-directory.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'An overview of federations and associations in the fashion industry',
  },
  'manufacturer-directory.title': {
    id: 'manufacturer-directory.title',
    defaultMessage: 'Manufacturer Directory',
  },
  'manufacturer-directory.description': {
    id: 'manufacturer-directory.description',
    defaultMessage: 'Manufacturer directory',
  },
  'software-suppliers-directory.title': {
    id: 'software-suppliers-directory.title',
    defaultMessage: 'Software in Fashion Directory',
  },
  'software-suppliers-directory.description': {
    id: 'software-suppliers-directory.description',
    defaultMessage: 'Software in Fashion Directory',
  },
  'agency-directory.title': {
    id: 'agency-directory.title',
    defaultMessage: 'Agency Directory',
  },
  'agency-directory.description': {
    id: 'agency-directory.description',
    defaultMessage: 'Agency Directory',
  },
  'retail-directory.title': {
    id: 'retail-directory.title',
    defaultMessage: 'Retail Directory',
  },
  'retail-directory.description': {
    id: 'retail-directory.description',
    defaultMessage: 'Retail directory',
  },
  'fashionweeks-directory.title': {
    id: 'fashionweeks-directory.title',
    defaultMessage: 'Fashion Weeks Directory',
  },
  'fashionweeks-directory.description': {
    id: 'fashionweeks-directory.description',
    defaultMessage: 'Fashion Weeks directory',
  },
  sorting: {
    id: 'sorting',
    defaultMessage: 'Sorting',
  },
  latest: {
    id: 'latest',
    defaultMessage: 'Latest',
  },
  alphabetical: {
    id: 'alphabetical',
    defaultMessage: 'Alphabetical',
  },
  favorites: {
    id: 'favorites',
    defaultMessage: 'Favorites',
  },
  emailSuggestion: {
    id: 'emailSuggestion',
    defaultMessage: 'Did you mean {email}?',
  },
  all: {
    id: 'all',
    defaultMessage: 'All',
  },
  'cookieConsent.cookies.title': {
    id: 'cookieConsent.cookies.title',
    defaultMessage: 'Cookies',
  },
  'newsletterForm.title': {
    id: 'newsletterForm.title',
    defaultMessage: 'Yes, I want to subscribe to the newsletter',
  },
  'newsletterForm.mailChimpListId': {
    id: 'newsletterForm.mailChimpListId',
    defaultMessage: '8c24d76aa5',
  },
  'newsletterForm.description': {
    id: 'newsletterForm.description',
    // eslint-disable-next-line max-len
    defaultMessage: '<p>Join the fashion community of entrepreneurs, CEOs, designers, buyers and other fashion professionals already receiving the FashionUnited newsletter.</p><blockquote><strong>Over 250,000 people&nbsp;</strong>are already up-to-date with the latest developments in the industry.&nbsp;<strong>Sign up now!</strong></blockquote><p>By subscribing to the free FashionUnited newsletter you will always be up-to-date with the latest developments in the fashion industry. You will receive newsletters throughout the week on Monday, Wednesday and Friday, and a weekly fashion news highlights overview on Sunday.</p>',
  },
  'newsletterForm.submitLabel': {
    id: 'newsletterForm.submitLabel',
    defaultMessage: 'Yes, keep me up to date',
  },
  'newsletterForm.disclaimerMessage': {
    id: 'newsletterForm.disclaimerMessage',
    // eslint-disable-next-line max-len
    defaultMessage: 'We care about the protection of your data. Read our <a href="/privacy" target="_blank" noreferrer noopener>Privacy Policy.</a>',
  },
  newsletterMetaTitle: {
    id: 'newsletterMetaTitle',
    defaultMessage: 'Subscribe to the newsletter',
  },
  newsletterMetaDescription: {
    id: 'newsletterMetaDescription',
    defaultMessage: 'Subscribe to the American newsletter',
  },
  codeOfEthics: {
    id: 'codeOfEthics',
    defaultMessage: 'Code of Ethics',
  },
  'termsAndConditions.title': {
    id: 'termsAndConditions.title',
    defaultMessage: 'General Terms and Conditions',
  },
  'fashionUnitedNews.title': {
    id: 'fashionUnitedNews.title',
    defaultMessage: 'FashionUnited News',
  },
  'fashionUnitedNewsletterInfo.title': {
    id: 'fashionUnitedNewsletterInfo.title',
    defaultMessage: 'FashionUnited Newsletters',
  },
  fashionweekweb: {
    id: 'fashionweekweb',
    defaultMessage: 'FashionWeekWeb',
  },
  'internationalNetwork.title': {
    id: 'internationalNetwork.title',
    defaultMessage: 'International Network',
  },
  'privacyStatement.title': {
    id: 'privacyStatement.title',
    defaultMessage: 'Privacy Statement',
  },
  freeForCommercialUse: {
    id: 'freeForCommercialUse',
    defaultMessage: 'Free for commercial use',
  },
  freeForEditorialUse: {
    id: 'freeForEditorialUse',
    defaultMessage: 'Free for editorial use',
  },
  internalUseOnly: {
    id: 'internalUseOnly',
    defaultMessage: 'Internal use only',
  },
  noAttributionRequired: {
    id: 'noAttributionRequired',
    defaultMessage: 'No attribution required',
  },
  lookbook: {
    id: 'lookbook',
    defaultMessage: 'lookbook',
  },
  lookbookBackButtonLabel: {
    id: 'lookbookBackButtonLabel',
    defaultMessage: 'Back to lookbook',
  },
  lookbookCredits: {
    id: 'lookbookCredits',
    defaultMessage: 'Image credits',
  },
  lookbookImageDescription: {
    id: 'lookbookImageDescription',
    defaultMessage: 'Image description',
  },
  lookbookImageTitle: {
    id: 'lookbookImageTitle',
    defaultMessage: 'Image title',
  },
  sustainableFashionTitle: {
    id: 'sustainableFashionTitle',
    // eslint-disable-next-line max-len
    defaultMessage: 'Laatste ontwikkelingen in duurzame mode en duurzaamheid innovaties bij kledingmerken',
  },
  sustainableFashionMetaDescription: {
    id: 'sustainableFashionMetaDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'Het laatste nieuws over duurzame mode, trends en duurzaamheid innovaties in de modebranche. Informatie over duurzame merken, innovatieve materialen, MVO, uitdagingen in de supply chain en de meest duurzame kledingmerken.',
  },
  sustainabilityTitle: {
    id: 'sustainabilityTitle',
    // eslint-disable-next-line max-len
    defaultMessage: 'The latest sustainable fashion news, trends and innovation',
  },
  sustainabilityDescription: {
    id: 'sustainabilityDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'Read all about the latest sustainable fashion news, trends and innovation in the clothing industry. You will find information about sustainable fashion, innovative materials, CSR, supply chain challenges and the most sustainable clothing brands.',
  },
  sustainableTitle: {
    id: 'sustainableTitle',
    // eslint-disable-next-line max-len
    defaultMessage: 'The latest sustainable fashion news, trends and innovation',
  },
  sustainableDescription: {
    id: 'sustainableDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'Read all about the latest sustainable fashion news, trends and innovation in the clothing industry. You will find information about sustainable fashion, innovative materials, CSR, supply chain challenges, sustainability certificates, case studies and the most sustainable clothing brands.',
  },
  nachhaltigeModeTitle: {
    id: 'nachhaltigeModeTitle',
    // eslint-disable-next-line max-len
    defaultMessage: 'Die aktuellsten Nachrichten über nachhaltige Mode, Trends und Innovationen',
  },
  nachhaltigeModeDescription: {
    id: 'nachhaltigeModeDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'Hier gibt es alle Informationen über die aktuellen Nachrichten in nachhaltiger Mode, Trends und Innovationen in der Industrie. Lese alles über Nachhaltigkeit, innovative Materialien, CSR, Herausforderungen in der Produktion, Nachhaltigkeitszertifikate, Fallstudien und die nachhaltigsten Modemarken.',
  },
  modaSostenibleTitle: {
    id: 'modaSostenibleTitle',
    // eslint-disable-next-line max-len
    defaultMessage: 'Últimas noticias sobre moda sostenible, tendencias y novedades',
  },
  modaSostenibleDescription: {
    id: 'modaSostenibleDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'Conozca las últimas noticias sobre moda sostenible, tendencias y las novedades del sector. Le invitamos a leer sobre sostenibilidad, materiales innovadores, RSC, retos en materia de producción, certificados de sostenibilidad, casos prácticos y las marcas de moda más sostenibles.',
  },
  'image.credits': {
    id: 'image.credits',
    defaultMessage: 'Credits',
  },
  'image.creditsHelperText': {
    id: 'image.creditsHelperText',
    // eslint-disable-next-line max-len
    defaultMessage: 'Please remember to credit ©Launchmetrics/spotlight when using their photos',
  },
  motivation: {
    id: 'motivation',
    defaultMessage: 'Motivation',
  },
  retryLabel: {
    id: 'retryLabel',
    defaultMessage: 'Retry',
  },
  'employerBranding.header': {
    id: 'employerBranding.header',
    defaultMessage: 'Advertising & Employer Branding',
  },
  'employerBranding.subHeader': {
    id: 'employerBranding.subHeader',
    // eslint-disable-next-line max-len
    defaultMessage: 'Connect with fashion professionals globally and reach your ideal target group with targeted employer branding.',
  },
  'employerBranding.brandsHiring': {
    id: 'employerBranding.brandsHiring',
    defaultMessage: 'Brands hiring with FashionUnited',
  },
  'employerBranding.advertorialsHeader': {
    id: 'employerBranding.advertorialsHeader',
    defaultMessage: 'Content marketing through Advertorials',
  },
  'employerBranding.advertorialsBody': {
    id: 'employerBranding.advertorialsBody',
    // eslint-disable-next-line max-len
    defaultMessage: 'FashionUnited creates valuable and relevant content through unique storytelling in the form of advertorials to attract and retain an engaged global audience of fashion professionals. FashionUnited encourages to thought leadership making the fashion industry more transparent and efficient.',
  },
  'employerBranding.ebpHeader': {
    id: 'employerBranding.ebpHeader',
    defaultMessage: 'Employer Branding',
  },
  'employerBranding.ebpBody': {
    id: 'employerBranding.ebpBody',
    // eslint-disable-next-line max-len
    defaultMessage: 'A strong employer branding is crucial in recruiting and talent aquisition. FashionUnited offers the needed services to spread a positive brand image within the global fashion industry. Through the global B2B network, talented candidates are easily attracted.',
  },
  'employerBranding.buttonLabel': {
    id: 'employerBranding.buttonLabel',
    defaultMessage: 'Get in touch',
  },
  addProfile: {
    id: 'addProfile',
    defaultMessage: 'Add profile',
  },
  browseLogos: {
    id: 'browseLogos',
    defaultMessage: 'Browse Logos in Lookbook',
  },
  viewLess: {
    id: 'viewLess',
    defaultMessage: 'view less',
  },
  viewMore: {
    id: 'viewMore',
    defaultMessage: 'view more',
  },
  'contentMarketing.header': {
    id: 'contentMarketing.header',
    defaultMessage: 'Content Marketing',
  },
  'contentMarketing.description': {
    id: 'contentMarketing.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'FashionUnited is a leading B2B news online platform for the fashion industry, offering various content marketing strategies to guide fashion companies in strengthening their narrative. Fashion-related editorial content with promotional messaging, such as advertorials, provide a unique opportunity for fashion brands to reach a targeted audience, showcase their products or services, and engage potential customers in an authentic and informative way. Explore the different packages FashionUnited offers to boost the Content Marketing for international fashion companies.',
  },
  'contentMarketing.advertorialTitle': {
    id: 'contentMarketing.advertorialTitle',
    defaultMessage: 'Advertorial including newsletter',
  },
  'contentMarketing.partnerContentTitle': {
    id: 'contentMarketing.partnerContentTitle',
    defaultMessage: 'Partner content',
  },
  'contentMarketing.advertorialBody': {
    id: 'contentMarketing.advertorialBody',
    // eslint-disable-next-line max-len
    defaultMessage: 'Advertorial articles with images or embedded videos on the FashionUnited homepage. Content is provided by the partner company. The FashionUnited newsletter is delivered 3 times per week to over 270k active subscribers, across 11 different countries, in their native language.',
  },
  'contentMarketing.advertorialIncludingNewsletterTitle': {
    id: 'contentMarketing.advertorialIncludingNewsletterTitle',
    defaultMessage: 'Advertorial including newsletter',
  },
  'contentMarketing.advertorialIncludingNewsletterBody': {
    id: 'contentMarketing.advertorialIncludingNewsletterBody',
    // eslint-disable-next-line max-len
    defaultMessage: 'Advertorial article with images on the FashionUnited homepage. Content is provided by the partner company. The advertorial is online for an unlimited time. Included in country newsletter.',
  },
  'contentMarketing.companyPageTitle': {
    id: 'contentMarketing.companyPageTitle',
    defaultMessage: 'Company page',
  },
  'contentMarketing.companyPageBody': {
    id: 'contentMarketing.companyPageBody',
    // eslint-disable-next-line max-len
    defaultMessage: 'FashionUnited creates company pages for all types of businesses operating in the fashion industry. The page is similar to a mini-website about the company that helps improve SEO and is linked in various articles, giving readers an easy way to learn more about your company.',
  },
  'contentMarketing.translationTitle': {
    id: 'contentMarketing.translationTitle',
    defaultMessage: 'Translation',
  },
  'contentMarketing.translationBody': {
    id: 'contentMarketing.translationBody',
    // eslint-disable-next-line max-len
    defaultMessage: 'Professional translations of articles into one of the local languages where FashionUnited is present to reach the target audience in the native language.',
  },
  'contentMarketing.interviewTitle': {
    id: 'contentMarketing.interviewTitle',
    defaultMessage: 'Interview',
  },
  'contentMarketing.interviewBody': {
    id: 'contentMarketing.interviewBody',
    // eslint-disable-next-line max-len
    defaultMessage: 'Interview conducted by a commercial editor to provide valuable insights and opinions to support the companys narrative through providing insights in the field of expertise.',
  },
  'contentMarketing.socialMediaCampaignTitle': {
    id: 'contentMarketing.socialMediaCampaignTitle',
    defaultMessage: 'Social media campaign',
  },
  'contentMarketing.socialMediaCampaignBody': {
    id: 'contentMarketing.socialMediaCampaignBody',
    // eslint-disable-next-line max-len
    defaultMessage: 'Promote events, advertorials, vacancies or other content through targeted social media campaigns on Facebook, LinkedIn, Instagram, and TikTok',
  },
  'contentMarketing.buttonLabel': {
    id: 'contentMarketing.buttonLabel',
    defaultMessage: 'Get in touch',
  },
  or: {
    id: 'or',
    defaultMessage: 'or',
  },
  'displayAdvertising.header': {
    id: 'displayAdvertising.header',
    defaultMessage: 'Display Advertising',
  },
  'displayAdvertising.subHeader': {
    id: 'displayAdvertising.subHeader',
    defaultMessage: 'Why FashionUnited Display Advertisement?',
  },
  'displayAdvertising.description': {
    id: 'displayAdvertising.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Elevate your brand presence and reach your desired audience through FashionUnited’s diverse range of banner options strategically placed on the FashionUnited home, article pages, and more.',
  },
  'displayAdvertising.bannersHeader': {
    id: 'displayAdvertising.bannersHeader',
    defaultMessage: 'Banners',
  },
  'displayAdvertising.bannersBody': {
    id: 'displayAdvertising.bannersBody',
    // eslint-disable-next-line max-len
    defaultMessage: '<p>FashionUnited is a leading fashion industry platform that offers B2B news, insights, and career opportunities. Banner publication on FashionUnited provides an effective way for fashion brands and businesses to promote their latest events, campaigns, and promotions to a global audience of fashion professionals and enthusiasts.</p>',
  },
  'displayAdvertising.jobBoardBannerHeader': {
    id: 'displayAdvertising.jobBoardBannerHeader',
    defaultMessage: 'Job Board Banners',
  },
  'displayAdvertising.jobBoardBannerBody': {
    id: 'displayAdvertising.jobBoardBannerBody',
    // eslint-disable-next-line max-len
    defaultMessage: 'Display job board banners on FashionUnited are an effective way to promote job opportunities to a targeted audience of fashion professionals. With a global reach, the job banner reaches a highly engaged audience, increasing the visibility of the open vacancies and thus attracting top talents.',
  },
  'displayAdvertising.visibilityHeader': {
    id: 'displayAdvertising.visibilityHeader',
    defaultMessage: 'Visibility',
  },
  'displayAdvertising.visibilityBody': {
    id: 'displayAdvertising.visibilityBody',
    // eslint-disable-next-line max-len
    defaultMessage: "Maximize your brand's visibility with strategically placed banners on FashionUnited’s high-traffic home and article pages.",
  },
  'displayAdvertising.targetedReachHeader': {
    id: 'displayAdvertising.targetedReachHeader',
    defaultMessage: 'Targeted Reach',
  },
  'displayAdvertising.targetedReachBody': {
    id: 'displayAdvertising.targetedReachBody',
    // eslint-disable-next-line max-len
    defaultMessage: 'Tailor your campaign to specific countries, ensuring your message reaches the right audience in their native language.',
  },
  'displayAdvertising.versatilityHeader': {
    id: 'displayAdvertising.versatilityHeader',
    defaultMessage: 'Versatility',
  },
  'displayAdvertising.versatilityBody': {
    id: 'displayAdvertising.versatilityBody',
    // eslint-disable-next-line max-len
    defaultMessage: 'Link banners to articles, jobs, or external websites, offering versatility in achieving your advertising objectives.',
  },
  'displayAdvertising.newsletterAmplificationHeader': {
    id: 'displayAdvertising.newsletterAmplificationHeader',
    defaultMessage: 'Newsletter Amplification',
  },
  'displayAdvertising.newsletterAmplificationBody': {
    id: 'displayAdvertising.newsletterAmplificationBody',
    // eslint-disable-next-line max-len
    defaultMessage: 'Increase your company’s exposure by including banners in FashionUnited’s newsletters, reaching a wider and dedicated audience.',
  },
  'displayAdvertising.contactUs': {
    id: 'displayAdvertising.contactUs',
    // eslint-disable-next-line max-len
    defaultMessage: "Elevate your brand's presence in the fashion industry with FashionUnited's Display Advertisement solutions. Contact us today to discuss how we can tailor a campaign that aligns with your unique objectives and ensures maximum impact in the vibrant world of fashion advertising.",
  },
  resetAndReload: {
    id: 'resetAndReload',
    defaultMessage: 'Reset and reload',
  },
  last12MonthsStatistics: {
    id: 'last12MonthsStatistics',
    defaultMessage: 'Statistics over the last 12 months ',
  },
  last: {
    id: 'last',
    defaultMessage: 'Last',
  },
  days: {
    id: 'days',
    defaultMessage: 'days',
  },
  now: {
    id: 'now',
    defaultMessage: 'Now',
  },
  'submitJobPostings.header': {
    id: 'submitJobPostings.header',
    defaultMessage: 'Submit Job Postings',
  },
  'submitJobPostings.description': {
    id: 'submitJobPostings.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'FashionUnited is there to boost your HR & marketing strategy in various ways, for instance by connecting fashion businesses with job seekers and vice versa.',
  },
  'submitJobPostings.buttonLabel': {
    id: 'submitJobPostings.buttonLabel',
    defaultMessage: 'Post your job',
  },
  'submitJobPostings.bodyText': {
    id: 'submitJobPostings.bodyText',
    // eslint-disable-next-line max-len
    defaultMessage: '<div><h2 id="benefits-when-posting-your-jobs-with-fashionunited">Benefits when posting your jobs with FashionUnited</h2><ul><li><p><strong>Reaching fashion professionals:</strong></p><p>FashionUnited is a B2B platform for the fashion industry</p></li><li><p><strong>Almost 2 decades of industry experience:</strong></p><p>80% of the global top 100 fashion companies trust FashionUnited</p></li><li><p><strong>More than a job board:</strong></p><p>FashionUnited provides you with customized HR &amp; marketing solutions</p></li><li><p><strong>Best support department:</strong></p><p>Skilled professionals are here to help you</p></li><li><p><strong>Know-how on national and international scope in fashion</strong></p></li></ul></div>',
  },
  'submitJobPostings.callToActionText': {
    id: 'submitJobPostings.callToActionText',
    defaultMessage: '<h2 id="get-in-touch">Get in touch</h2><p>For more information about tailor made partnerships, HR content marketing strategies, branding solutions and pricing, please contact your <a href="https://fashionunited.info/contact"><strong>local consultant</strong></a>.</p><p>For general requests regarding job postings<br> Email: <a href="mailto:jobs@fashionunited.com">jobs@fashionunited.com</a></p><p>For general requests regarding media inquiries<br> Email: <a href="mailto:media@fashionunited.com">media@fashionunited.com</a></p>',
  },
  reset: {
    id: 'reset',
    defaultMessage: 'Reset',
  },
  specialsDirectoryTitle: {
    id: 'specialsDirectoryTitle',
    defaultMessage: 'Special Pages',
  },
  specialsDirectoryMetaDescription: {
    id: 'specialsDirectoryMetaDescription',
    defaultMessage: 'Pages for special topics on FashionUnited',
  },
  dashboard: {
    id: 'dashboard',
    defaultMessage: 'Dashboard',
  },
  'jobs.showJobsForAllCountries': {
    id: 'jobs.showJobsForAllCountries',
    defaultMessage: 'Show jobs for all countries',
  },
  'intelligence.header': {
    id: 'intelligence.header',
    defaultMessage: 'Fashion Business Data',
  },
  'intelligence.introText': {
    id: 'intelligence.introText',
    // eslint-disable-next-line max-len
    defaultMessage: '<p>FashionUnited Business Intelligence provides fashion professionals from across the world with the most relevant apparel market data and analytics, offering a comprehensive overview of the global fashion industry.</p><p>This includes FashionUnited’s Top 100 Companies list by market capitalization, Facebook Fashion Index, Twitter Fashion Index, Fashion Fortune 200, retail statistics, financial news (monthly, quarterly, annually), commodity news, stock news, country statistics, company directory and more.</p>',
  },
  'intelligence.description': {
    id: 'intelligence.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'FashionUnited Intelligence pages provides fashion professionals with data, analysis and insights on the fashion industry.'
  },
  'companyLandingPage.title': {
    id: 'companyLandingPage.title',
    defaultMessage: 'FashionUnited\'s Global Fashion Network',
  },
  'companyLandingPage.description': {
    id: 'companyLandingPage.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Connect with fashion professionals globally and reach your ideal target group on the fashion industry\'s number one platform: FashionUnited'
  },
  'companyLandingPage.publishVacancies.buttonLabel': {
    id: 'companyLandingPage.publishVacancies.buttonLabel',
    defaultMessage: 'Learn about vacancies',
  },
  'companyLandingPage.employerBranding.buttonLabel': {
    id: 'companyLandingPage.employerBranding.buttonLabel',
    defaultMessage: 'Learn about branding',
  },
  'companyLandingPage.contentMarketing.buttonLabel': {
    id: 'companyLandingPage.contentMarketing.buttonLabel',
    defaultMessage: 'Learn about marketing',
  },
  'companyLandingPage.displayAdvertising.buttonLabel': {
    id: 'companyLandingPage.displayAdvertising.buttonLabel',
    defaultMessage: 'Learn about advertising',
  },
  'companyLandingPage.publishVacancies.description': {
    id: 'companyLandingPage.publishVacancies.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Advertise your open position on the FashionUnited job board and find the perfect candidate reaching thousands of fashion professionals around the world. Post your job ad on our global platform for as little as €199 with the option to upgrade your ad with our performance enhancers'
  },
  'companyLandingPage.publishVacancies.title': {
    id: 'companyLandingPage.publishVacancies.title',
    defaultMessage: 'Publish Vacancies',
  },
  'companyLandingPage.employerBranding.description': {
    id: 'companyLandingPage.employerBranding.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'A strong employer brand is crucial in recruiting and talent acquisition. FashionUnited will help you to spread a positive brand image within the global fashion industry and attract talented candidates easily.'
  },
  'companyLandingPage.employerBranding.title': {
    id: 'companyLandingPage.employerBranding.title',
    defaultMessage: 'Employer Branding',
  },
  'companyLandingPage.contentMarketing.description': {
    id: 'companyLandingPage.contentMarketing.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'FashionUnited creates valuable and relevant content through unique storytelling in the form of advertorials to attract and retain an engaged global audience of fashion professionals. We encourage our partners to thought leadership, making the fashion industry more transparent and efficient.'
  },
  'companyLandingPage.contentMarketing.title': {
    id: 'companyLandingPage.contentMarketing.title',
    defaultMessage: 'Content Marketing',
  },
  'companyLandingPage.displayAdvertising.description': {
    id: 'companyLandingPage.displayAdvertising.description',
    // eslint-disable-next-line max-len
    defaultMessage: 'Reach an international fashion B2B audience and boost your online traffic with banners on our website. FashionUnited display ads create visibility, increase brand awareness and drive traffic to your desired webpage.'
  },
  'companyLandingPage.displayAdvertising.title': {
    id: 'companyLandingPage.displayAdvertising.title',
    defaultMessage: 'Display Advertising',
  },
  'companyLandingPage.metaDescription': {
    id: 'companyLandingPage.metaDescription',
    // eslint-disable-next-line max-len
    defaultMessage: 'FashionUnited is the number one platform for the global fashion industry. Connect with fashion professionals globally and reach your ideal target group.'
  },
  relatedCompanies: {
    id: 'relatedCompanies',
    defaultMessage: 'Related companies',
  }
});
